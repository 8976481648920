<template>
  <div class="container" ref="wrapper">
    <van-nav-bar title="员工生日" left-arrow @click-left="$router.back()">
    </van-nav-bar>



<van-row>
      <van-col span="8">
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="moth"
            :options="mothOption"
            @change="onSearch"
          />
        </van-dropdown-menu>
      </van-col>
      <van-col span="16">
        <van-search
          v-model="keyWord"
          @search="onSearch"
          placeholder="请输入搜索关键词"
        />
      </van-col>
    </van-row>

     <!-- <van-search
          v-model="keyWord"
          @search="onSearch"
          placeholder="请输入搜索关键词"
        /> -->

    <van-list
      v-model="upLoading"
      :finished="finished"
      @load="onLoad"
      :finished-text="'共'+articles.length+'人'"
    >
      <van-cell-group>
        <van-cell v-for="(item,index) in articles" :key="index">
          <van-col span="3">  <img src="/image/birthday cake.png" style="max-width: 30px" /></van-col>
          <van-col span="5">  <h3 style="display: inline-block">{{ item.birthday }}</h3> </van-col>
          <van-col span="16">  <h3 style="display: inline-block">{{ item.trueName }}</h3> {{item.department}} 
            {{item.age&&"年龄:"+item.age}} 
            {{item.yearGrade && "年度职级:"+item.yearGrade}}
          </van-col>
        </van-cell>
      </van-cell-group>
    </van-list>

  </div>
</template>

<script>
import { getUserBirthday } from "@/api/systemmessage";

//const key = "zn-history";
export default {
  name: "SystemMessageBirthday",
  data() {
    return {
      refreshing: false,
      upLoading: false,
      finished: false,
      articles: [],
      sorterType: 0,
      keyWord:"",
      moth:0,
      mothOption:[
      ]
      
    };
  },

  created() {
    this.mothOption= Array.from(Array(13), (v, k) => (
     
      {text: k==0?'全部':k+'月', value: k}
    ));
    this.initData();
    
  },
  updated() {
  },

  watch: {
    // $route(to, from) {
    // },
  },

  methods: {
    DateFromat(data) {
      if (!data) {
        return "";
      }
      return data.replace(" 00:00:00", "").replace("T00:00:00", "");
    },

    async initData() {
      this.sorterType = 0;
      this.onSearch();
    },

    onSearch() {
      this.articles = [];
      this.onLoad();
    },

    async onLoad() {
      this.upLoading = true;
      let aresult = await getUserBirthday(this.sorterType,this.keyWord,this.moth); //({ ...this.page })
      if(aresult.data.data){
      this.articles.push(...aresult.data.data);
      }
      this.upLoading = false;
      this.finished = true;
    },
    changeSorterType(sorterType) {
      if (this.sorterType != sorterType) {
        this.sorterType = sorterType;
        this.onSearch();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
</style>
